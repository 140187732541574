.blockquote {
  margin: 5px 0;
  padding-left: 1.5rem;
  border-left: 5px solid teal;
}
.marginBlock {
  margin-left: 10px;
}
.paddin {
  vertical-align: bottom;
  padding-top: 90%;
  padding-left: -10%;
}
.select-control {
  height: 30px;
}
.select-placeholder {
  line-height: 26px;
}

.select-value {
  line-height: 26px !important;
}
.select-value-label {
  line-height: 26px;
}

.thick {
  font-weight: bold;
  color: #002c55;
}
.norm {
  font-weight: normal;
  color: gray;
}
.select-input {
  height: 26px;
}

.tabs .tab a {
  color: #002c55;
  font-weight: 500;
  font-size: 15px;
} /*Black color to the text*/

.tabs .tab a:hover {
  background-color: #bae8bb;
  color: #000;
} /*Text color on hover*/

.tabs .tab a.active {
  background-color: teal;
  color: #000;
} /*Background and text color when a tab is active*/

.tabs .indicator {
  background-color: #4b954e;
} /*Color of underline*/
/* icon prefix focus color */
.input-field .prefix.active {
  color: #000;
}
.table {
  border-collapse: separate;
  border-spacing: 3px;
  border-width: 10px;
  border-color: black;
}
table.striped > thead > tr:nth-child(odd) {
  /*background-color: teal;*/
}
.textHead {
  font-weight: 500;
  font-size: 15px;
  font-family: cursive;
}
.tableCell {
  width: 100%;
}
.hover {
  color: red;
  margin: 25px 0;
  cursor: pointer;
}

.pt-botton{
  padding-top: 3% !important; 
}