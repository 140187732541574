.Actions {
  display: flex;
  flex: 1;
  width: 100%;
  align-items: center;
  flex-direction: column;
  margin-top: 32px;
}

.mr {
  margin-right: 10px;
}

.text-align-right{
  text-align: right;
}